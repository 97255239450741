import { BaseModel } from '~/models/baseModel';
export class EdiModel extends BaseModel {
    public ediCode:string;
    public isActive:boolean;

    constructor(values: Object = {}) {
        super(values);
    }

}
