<div id="page-tpl__main" class="page-tpl__main">
    <div class="page-tpl__main__inner">
        <div class="page-tpl__container">
            <div class="page-tpl__heading flex-container">
                <h1 class="page-tpl__heading__left">
                    <ng-content select="wep-page-tpl-admin-default-heading-left"></ng-content>
                </h1>
                <div class="flex-child-grow"></div>
                <div class="page-tpl__heading__right" #headingRight [hidden]="!hasHeadingRight">
                    <ng-content select="wep-page-tpl-admin-default-heading-right"></ng-content>
                </div>
            </div>
            <div class="page-tpl__subheading flex-container">
                <div class="page-tpl__subheading__left">
                    <ng-content select="wep-page-tpl-admin-default-subheading-left"></ng-content>
                </div>
            </div>
            <div class="page-tpl__content">
                <ng-content select="wep-page-tpl-admin-default-content"></ng-content>
            </div>
        </div>
    </div>
    <div class="page-tpl__space"></div>
    <ng-content select="wep-page-tpl-admin-default-footer"></ng-content>
    <wep-page-tpl-footer></wep-page-tpl-footer>
</div>
<div class="page-tpl__sidebar">
    <ng-content select="wep-page-tpl-admin-default-sidebar"></ng-content>
</div>
