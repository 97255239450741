import { PermissionModel } from '~/models/permissionModel';
import { EdiModel } from '~/models/ediModel';
import { WoolGrowerExtensionModel } from '~/models/woolGrowerExtensionModel';
import { DefaultSpeciSettingModel } from '~/models/defaultSpeciSettingModel';
import { ClasserModel } from '~/models/classerModel';
import { BuyerModel } from '~/models/buyerModel';
import { SellerModel } from '~/models/sellerModel';
import { SellerFeesModel } from '~/models/sellerFeesModel';
import { WoolStoreLocationModel } from '~/models/woolStoreLocationModel';
import { SellerServiceModel } from '~/models/sellerServiceModel';
import { PartnerModel } from '~/models/partnerModel';
import { BrandModel } from '~/models/brandModel';
import { BankModel } from '~/models/bankModel';
import { CertificateModel } from '~/models/certificateModel';
import { UserModel } from '~/models/userModel';
import { GalleryImageModel } from '~/models/galleryImageModel';
import { FtpConnectionModel } from '~/models/ftpConnectionModel';
import { forEach as _forEach, map as _map } from 'lodash';
import { GroupExtensionModel } from './groupExtensionModel';
import { GroupSubscriberModel } from './groupSubscriberModel';
import { ShearingTimesModel } from '~/models/shearingTimesModel';
import { ProcessorModel } from './overseasProcessorModel';
import { GalleryVideoModel } from './galleryVideoModel';
import { GalleryModel } from './galleryModel';
import { BaseModel } from './baseModel';
import { DataVisibilityModel } from './dataVisibilityModel';
import { DataflowSettingsModel } from './dataflowSettingsModel';

interface InstanceInstruction {
    prop:string;
    model:Function;
}

export enum EntityTypes {
    Woolgrower = 'Woolgrower',
    SellerBuyer = 'Seller / Buyer',
    Seller = 'Seller',
    Buyer = 'Buyer',
    Classer = 'Classer',
    Contractor = 'Contractor / Other',
    Group = 'Group',
    Processor = 'Overseas Processor',
    Brand = 'Brand'
}

export let entityTypesArray:EntityTypes[] = _map(Object.keys(EntityTypes), (entityType) => {
    return EntityTypes[entityType];
});

export class LiteEntityModel extends BaseModel {
    public entityId:number;
    public canAddPartner:boolean;

    constructor(values: Object = {}) {
        super(values);
    }
}

export class EntityCoreModel extends PermissionModel {
    public entityId:number;
    public entityName:string;

    constructor(values: Object = {}) {
        super(values);
    }
}
export class EntityModel extends PermissionModel {
    public entityId:number;
    public entityName:string;
    public businessDescription:string;
    public abn:string;
    public acn:string;
    public edi: string;
    public gst: boolean;
    public paymentMethodId:number;
    public guid:string;

    public streetAddress:string;
    public streetAddress2:string;

    public city:string;
    public state:string;
    public country:string;
    public suburb:string;
    public postcode:string;
    public wsa:string;
    public telephone:string;
    public mobile:string;
    public email:string;
    public website:string;

    // bank details
    public bsb:string;
    public bankName:string;
    public accountNo:string;
    public swiftCode:string;
    public iban:string;

    public isActive:boolean;
    public isWoolGrower:boolean;
    public isClasser:boolean;
    public isSeller:boolean;
    public isBuyer:boolean;
    public isContractor:boolean;
    public isGroup:boolean;
    public isPresser:boolean;
    public isProcessor:boolean;
    public isBrand:boolean;
    public canTrade:boolean;

    public brandPubliclyVisible:boolean;

    public woolgrowerExtension:WoolGrowerExtensionModel;
    public classerExtension:ClasserModel;
    public buyerExtension:BuyerModel;
    public sellerExtension:SellerModel;
    public defaultSpeciSetting:DefaultSpeciSettingModel;
    public sellerFee:SellerFeesModel;
    public ftpConnection:FtpConnectionModel;
    public groupExtension:GroupExtensionModel;
    public dataVisibility:DataVisibilityModel;
    public dataflowSettings:DataflowSettingsModel;

    public woolStoreLocations:WoolStoreLocationModel[] = [];
    public sellerServices:SellerServiceModel[] = [];
    public partners:PartnerModel[] = [];
    public actAsGrowers:PartnerModel[] = [];
    public actAsSellers:PartnerModel[] = [];
    public brands:BrandModel[] = [];
    public certificates:CertificateModel[] = [];
    public users:UserModel[] = [];
    public galleryImages:GalleryImageModel[] = [];
    public galleryVideos:GalleryVideoModel[] = [];
    public gallery:GalleryModel;
    public groups:GroupSubscriberModel[] = [];

    public shearingTimes:ShearingTimesModel[] = [];
    public linkedEDIs:EdiModel[] = [];

    public businessLogoUploadedName:string;
    public businessPhotoUploadedName:string;
    public businessVideoUploadedName:string;

    public businessLogoStoredFileName:string;
    public businessPhotoStoredFileName:string;
    public businessVideoStoredFileName:string;

    public canAddPartner:boolean;
    public registeredForTrading:boolean;
    public tradingStatus:string;

    constructor(values: Object = {}) {
        super(values);

        let instructions:InstanceInstruction[] = <InstanceInstruction[]>[
            { prop : 'classerExtension',   model: (item) => new ClasserModel(item) },
            { prop : 'processorExtension',   model: (item) => new ProcessorModel(item) },
            { prop : 'sellerFee', model: (item) => new SellerFeesModel(item) },
            { prop : 'woolgrowerExtension', model: (item) => new WoolGrowerExtensionModel(item) },
            { prop : 'defaultSpeciSetting', model: (item) => new DefaultSpeciSettingModel(item) },
            { prop : 'sellerExtension', model: (item) => new SellerModel(item) },
            { prop : 'buyerExtension', model: (item) => new BuyerModel(item) },
            { prop : 'ftpConnection', model: (item) => new FtpConnectionModel(item) },
            { prop : 'groupExtension', model: (item) => new GroupExtensionModel(item) },
            { prop : 'dataVisibility', model: (item) => new DataVisibilityModel(item) },
            { prop : 'dataflowSettings', model: (item) => new DataflowSettingsModel(item) },
        ];

        this.forEachInstruction(values, instructions, (instr:InstanceInstruction, jsonData:{}) => {
            this[instr.prop] =  instr.model(jsonData);
        });

        let instructionsDeep:InstanceInstruction[] = <InstanceInstruction[]>[
            {prop : 'woolStoreLocations', model: (item) => new WoolStoreLocationModel(item) },
            {prop : 'sellerServices', model: (item) => new SellerServiceModel(item) },
            {prop : 'partners', model: (item) => new PartnerModel(item) },
            {prop : 'actAsGrowers', model: (item) => new PartnerModel(item) },
            {prop : 'actAsSellers', model: (item) => new PartnerModel(item) },
            {prop : 'brands', model: (item) => new BrandModel(item) },
            {prop : 'certificates', model: (item) => new CertificateModel(item) },
            {prop : 'users', model: (item) => new UserModel(item) },
            {prop : 'galleryImages', model: (item) => new GalleryImageModel(item) },
            {prop : 'groups', model: (item) => new GroupSubscriberModel(item) },
            {prop : 'linkedEDIs', model: (item) => new EdiModel(item) }
        ];

        this.forEachInstruction(values, instructionsDeep, (instr:InstanceInstruction, jsonData:{}) => {
            _forEach(jsonData, (data:{}) => {
                let item = instr.model(data);

                this[instr.prop].push(item);
            });
        });

        if (typeof values['shearingTimes'] !== 'undefined') {
            _forEach(values['shearingTimes'], (shearing:{}) => {
                let _shearing = new ShearingTimesModel(shearing);
                this.shearingTimes.push(_shearing);
            });
        }

        if (this.gallery) {
            this.galleryImages = this.gallery.images;
            this.galleryVideos = this.gallery.videos;
        }

    }

    get accountName() {
        return this.bankName;
    }

    set accountName(value) {
        this.bankName = value;
    }

    get type() : string {
        let type = '';

        type = (this.isWoolGrower) ? 'Woolgrower' : type;
        type = (this.isSeller) ? 'Seller' : type;
        type = (this.isBuyer) ? 'Buyer' : type;
        type = (this.isSeller && this.isBuyer) ? 'Seller / Buyer' : type;
        type = (this.isClasser) ? 'Classer' : type;
        type = (this.isContractor) ? 'Contractor / Other' : type;
        type = (this.isGroup) ? 'Group' : type;
        type = (this.isProcessor) ? 'Overseas Processor' : type;
        type = (this.isBrand) ? 'Brand' : type;
        return type;
    }

    // Displayed on the businessEdit page
    get typeFormatted() : string {
        let type = '';

        type = (this.isWoolGrower) ? 'Woolgrower' : type;
        type = (this.isSeller) ? 'Wool Seller' : type;
        type = (this.isBuyer) ? 'Wool Buyer' : type;
        type = (this.isSeller && this.isBuyer) ? 'Seller / Buyer' : type;
        type = (this.isClasser) ? 'Wool Classer' : type;
        type = (this.isContractor) ? 'Contractor / Other' : type;
        type = (this.isGroup) ? 'Group' : type;
        type = (this.isProcessor) ? 'Overseas Processor' : type;
        type = (this.isBrand) ? 'Brand' : type;

        return type;
    }

    set type(type:string) {
        this.isWoolGrower = (type === 'Woolgrower');
        this.isSeller = (type === 'Seller' || type === 'Seller / Buyer');
        this.isClasser = (type === 'Classer');
        this.isBuyer = (type === 'Buyer' || type === 'Seller / Buyer');
        this.isContractor = (type === 'Contractor / Other');
        this.isGroup = (type === 'Group');
        this.isProcessor = (type === 'Processor' || type === 'Overseas Processor');
        this.isBrand = (type == 'Brand');
    }

    public userIs(userType:string) : boolean {
        return (this.type === userType);
    }

    private forEachInstruction(values:Object = {}, instructions:InstanceInstruction[], closure:Function) : void {
        _forEach(instructions, (instr) => {
            let jsonData = (typeof values[instr.prop] !== 'undefined') ? values[instr.prop] : {};

            closure(instr, jsonData);
        });
    }

    set bank(bank:BankModel) {
        this.bankName = bank.bankName;
        this.bsb = bank.bsb;
        this.accountNo = bank.accountNo;
        this.iban = bank.iban;
        this.swiftCode = bank.swiftCode;
    }

    get bank() : BankModel {
        return new BankModel({
            'bsb': this.bsb,
            'accountNo': this.accountNo,
            'accountName': this.accountName,
            'iban': this.iban,
            'swiftCode': this.swiftCode
        });
    }

    set stores(stores:WoolStoreLocationModel[]) {
        this.woolStoreLocations = stores;
    }

    set buyer(buyer:BuyerModel) {
        this.buyerExtension = buyer;
    }

    set woolGrowerExtension(woolGrowerExtension:WoolGrowerExtensionModel) {
        this.woolgrowerExtension = woolGrowerExtension;
    }
}
